import React from 'react'
import { css } from 'glamor'
import { Row, Col } from 'react-flexbox-grid'
import DesktopBG from './desktop-bg.png'
import MobileBG from './mobile-bg.png'
import LaptopBG from './laptop-bg.png'
import Link from 'gatsby-link'

const Slogan = (props) => (
    <section className="sloganHolder" css={{
      backgroundImage: `url(${DesktopBG})`,
      height: 900,
      '@media (max-width: 1366px)': {
        backgroundImage: `url(${LaptopBG})`,
        height: 639
      },
      '@media (max-width: 768px)': {
        backgroundImage: `url(${MobileBG})`,
        height: 593
      }
    }}>
      <div className="slogan">
        <div className="callToAction">
          {props.content}
          <Link to="/#kontaktai" className="button">Susisiekti su mumis</Link>
        </div>
      </div>
    </section>

  )
  
  export default Slogan
  