import React from 'react'
import { css } from 'glamor'
import { Row, Col } from 'react-flexbox-grid'
import Link from 'gatsby-link';



const Contacts = () => (
  <div id="kontaktai" name="kontaktai" className="contacts">
    <div className="holder">
      <h2>Kontaktai</h2>
      <Row>
        <Col lg={4} xs={12}>
          <section>
              <h1>WISE MONKS, UAB</h1>
              <div>Paplaujos g. 3, 11342, Vilnius</div>
              <div>+370 616 34 780</div>
              <div>info@wisemonks.com</div>
          </section>
        </Col>
        <Col lg={8} xs={12}>
          <div className="lgOffset">
            <section className="form">
                <h2>Susisiekite su mumis ir atsakysime į visus Jums rūpimus klausimus</h2>
                <form action="https://formspree.io/info@wisemonks.com" method="POST">
                  
                <div class="group">      
                  <input type="text" name="phone" required />
                  <label>Jūsų telefono nr.</label>
                </div>
                  
                <div class="group">      
                  <input type="email" name="_replyto" required />
                  <label>El. paštas</label>
                </div>

                <div class="group">      
                  <textarea name="message" required></textarea>
                  <label>Kaip galėtume Jums padėti?</label>
                </div>
        

                  <button className="button">Siųsti</button>
                </form>
            </section>
          </div>
        </Col>
      </Row>
    </div>
  </div>
)

export default Contacts