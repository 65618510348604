import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'

const VVS = () => (
    <div>
    <section className="serviceItem">
        <h1>Verslo sistemos</h1>
        <h2>Jei daugiausia informacijos ir sudėtingiausi procesai vyksta jūsų įmonės viduje, jums reikalinga kokybiška verslo valdymo sistema, kuri nuimtų didelę dalį rankinio darbo nuo jūsų darbuotojų pečių ir padėtų jiems priimti geresnius sprendimus. </h2>
        <Link to="/verslo-sistemu-programavimas" className="button">Skaityti daugiau</Link>
    </section>
    </div>
)

export default VVS