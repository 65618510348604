import React from 'react'
import Link from 'gatsby-link'

const SS = () => (
    <div>
 
    <section className="serviceItem">
        
        <h1>Savitarnos sistemos</h1>
        <h2>Jei dirbate su pardavimais, mokesčiais arba tiesiog jūsų darbe yra svarbus nuolatinis kontaktas su klientais ir jų teikiama informacija, jums gali padėti saugi klientų savitarnos zona, kuri leistų nuimti dalį lengvai automatizuojamo krūvio nuo darbuotojų. </h2>
        <Link to="/savitarnu-sistemu-kurimas" className="button">Skaityti daugiau</Link>
      </section>
    </div>
)

export default SS