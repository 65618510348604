import React from 'react'
import { css } from 'glamor'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Slogan from '../Slogan'
import Logo from './wisemonks-logo.png'
import Link from 'gatsby-link'

const Header = (props) => (
  <header  className="header">
    
    <div className="holder">
      <Grid fluid className="topnav">
        <Link to="/" className="logo"><img src={Logo} alt="WISE MONKS"/></Link>
        <nav>
          <Link to="/#paslaugos">Paslaugos</Link>
          <Link to="/#paslaugu-eiga">Paslaugų eiga</Link>
          <Link to="/#klientai">Klientai</Link>
          <Link to="/#apie">Apie</Link>
          <Link to="/#kontaktai">Kontaktai</Link>
        </nav>
      </Grid>
    </div>


    <Slogan content={props.content} />

  </header>
)

export default Header
