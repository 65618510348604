import React from 'react'
import { css } from 'glamor'
import { Grid, Row, Col } from 'react-flexbox-grid'


import VVS from './vvs'
import SS from './ss'

const Services = () => (
  <div id="paslaugos" className="services">
   <div className="holder">
      <Grid fluid>
        <Row>
          <Col lg={6}>
            <VVS />
          </Col>
          <Col lg={6}>
            <SS />
          </Col>
      </Row>
      </Grid>
    </div>
  </div>
)

export default Services


